import React, { createContext, useContext, useState, useEffect } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const [isOrderingActive, setIsOrderingActive] = useState(null); // Global state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const db = getFirestore(); // Initialize Firestore
    const docRef = doc(db, "pageData", "preorder"); // Firestore path

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setIsOrderingActive(docSnap.data().isActive); // Update state with real-time data
      } else {
        console.error("No such document!");
        setIsOrderingActive(false); // Default to false if document doesn't exist
      }
      setLoading(false); // Ensure loading is updated after the first snapshot
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);

  return (
    <SiteContext.Provider value={{ isOrderingActive, loading }}>
      {children}
    </SiteContext.Provider>
  );
};

export const useSite = () => useContext(SiteContext);
