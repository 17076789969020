import {
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
  IonList,
  IonListHeader,
  IonModal,
  IonButton,
  IonBadge,
} from "@ionic/react";
import React, { useState } from "react";

const ReviewStep = ({
  selectedItems,
  setSelectedItems,
  formData,
  setFormData,
}) => {
  const calculateItemTotal = (price, quantity) => parseFloat(price) * quantity;
  const calculateTotalWithTax = (total) => total * 1.0725; // 7.25% tax rate

  // const removeSelectedItem = (id) => {
  //   const updatedItems = selectedItems.filter((item) => item.id !== id);
  //   setSelectedItems(updatedItems);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     selectedItems: updatedItems,
  //   }));
  // };

  const updateItemQuantity = (id, newQuantity) => {
    setSelectedItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  let grandTotal = 0;

  return (
    <IonRow className="flex flex-col h-full w-full overflow-auto rounded-md bg-mYellow">
      {/* Main Container */}
      <IonCol size="12" className="flex flex-col h-full w-full overflow-y-auto">
        {/* Contact Details */}
        <IonList className="border bg-white">
          <IonListHeader className="font-bold mb-2 text-center">
            Contact Details
          </IonListHeader>
          <IonItem>
            {formData.orderName && (
              <IonLabel>Order Name: {formData.orderName}</IonLabel>
            )}
          </IonItem>
          <IonItem>
            {formData.phoneNumber && (
              <IonLabel>Phone Number: {formData.phoneNumber}</IonLabel>
            )}
          </IonItem>
          <IonItem>
            {formData.email && <IonLabel>Email: {formData.email}</IonLabel>}
          </IonItem>
        </IonList>

        {/* Service Type and Address/Time Setup */}
        <IonList className="border bg-white">
          <IonListHeader className="font-bold mb-2 text-center">
            {formData.serviceType === "Pickup"
              ? "Pickup Information"
              : "Delivery Address"}
          </IonListHeader>
          {formData.serviceType === "Pickup" && (
            <>
              <IonItem>
                {formData.pickupTime && (
                  <IonLabel>Pickup Date: {formData.date}</IonLabel>
                )}
              </IonItem>
              <IonItem>
                {formData.pickupTime && (
                  <IonLabel>Pickup Time: {formData.pickupTime}</IonLabel>
                )}
              </IonItem>
              <IonItem>
                {formData.pickupTime && (
                  <IonLabel>Food Temp: {formData.tempType}</IonLabel>
                )}
              </IonItem>
            </>
          )}
          {formData.serviceType === "Delivery" && (
            <>
              <IonItem>
                {formData.street && (
                  <IonLabel>Street: {formData.street}</IonLabel>
                )}
              </IonItem>
              <IonItem>
                {formData.city && <IonLabel>City: {formData.city}</IonLabel>}
              </IonItem>
              <IonItem>
                {formData.state && <IonLabel>State: {formData.state}</IonLabel>}
              </IonItem>
            </>
          )}
        </IonList>

        {/* Cart Items */}
        <IonList className="border bg-white">
          <IonListHeader className="font-bold mb-2 text-center">
            Cart Items
          </IonListHeader>
          {selectedItems.map((item, index) => {
            const itemTotal = calculateItemTotal(item.price, item.quantity);
            grandTotal += itemTotal;

            return (
              <IonItem
                key={item.id || index}
                className="flex items-center justify-between"
              >
                <IonLabel>
                  <h2 className="font-semibold">{item.name}</h2>
                  <p>
                    Quantity: {item.quantity} &nbsp;|&nbsp; Price: $
                    {itemTotal.toFixed(2)}
                  </p>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>

        {/* Subtotal and Comments */}
        <IonList className="border bg-white">
          <IonListHeader className="text-center flex flex-col items-center py-4">
            <h2 className="font-bold text-center">
              Subtotal: ${grandTotal.toFixed(2)}
            </h2>
            <h4 className="font-bold text-lg text-center">
              Total w/ Tax: ${calculateTotalWithTax(grandTotal).toFixed(2)}
            </h4>
          </IonListHeader>
          <IonItem lines="none" className="p-2">
            <IonLabel position="floating">Additional Comments</IonLabel>
            <IonTextarea
              placeholder="Any additional notes for order"
              className="bg-white"
              value={formData.comments || ""}
              onIonChange={(e) =>
                setFormData({ ...formData, comments: e.detail.value })
              }
            />
          </IonItem>
        </IonList>
      </IonCol>
    </IonRow>
  );
};

export default ReviewStep;
