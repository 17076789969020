import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonText,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/react";
import { addCircle, removeCircle, cartOutline } from "ionicons/icons";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Cart from "../../Cart";

const MenuSelectionStep = ({
  cateringMenu,
  loading,
  error,
  setFormData,
  selectedItems,
  setSelectedItems,
}) => {
  const [showCartModal, setShowCartModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const allItems = Object.values(cateringMenu).flat();

  const handleSegmentChange = (event) => {
    setSelectedCategory(event.detail.value);
  };

  const syncSelectedItemsToFormData = (updatedItems) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedItems: updatedItems,
    }));
  };

  const addSelectedItem = (item, category) => {
    if (!item.name) {
      toast.error("Item name is required to add to cart!");
      return;
    }

    const updatedItems = [...selectedItems];
    const existingItem = updatedItems.find(
      (cartItem) => cartItem.name === item.name
    );

    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      updatedItems.push({
        name: item.name,
        category,
        quantity: 1,
        price: item.price || 0,
      });
    }

    setSelectedItems(updatedItems);
    syncSelectedItemsToFormData(updatedItems);
    toast.success(`${item.name} added to the cart!`);
  };

  const decreaseItemQuantity = (itemName) => {
    const updatedItems = selectedItems.reduce((acc, item) => {
      if (item.name === itemName) {
        if (item.quantity > 1) {
          acc.push({ ...item, quantity: item.quantity - 1 });
        } else {
          toast.info(`${item.name} removed from the cart!`);
        }
      } else {
        acc.push(item);
      }
      return acc;
    }, []);

    setSelectedItems(updatedItems);
    syncSelectedItemsToFormData(updatedItems);
  };

  const removeSelectedItem = (itemName) => {
    const updatedItems = selectedItems.filter((item) => item.name !== itemName);
    setSelectedItems(updatedItems);
    syncSelectedItemsToFormData(updatedItems);
    toast.info("Item removed from the cart!");
  };

  const isItemInCart = (itemName) => {
    return selectedItems.some((cartItem) => cartItem.name === itemName);
  };

  return (
    <div className="flex flex-col h-full w-full">
      <IonRow className="flex-grow rounded-md overflow-auto scrollbar-hide w-full">

        {loading && <IonCol>Loading menu...</IonCol>}
        {error && <IonCol>Error loading menu: {error}</IonCol>}

        <IonCol size="12" className="flex overflow-x-auto no-scrollbar">
          {(selectedCategory === "all"
            ? allItems
            : cateringMenu[selectedCategory]
          ).map((item) => (
            <MenuItemCard
              key={item.id || uuidv4()}
              item={item}
              category={selectedCategory}
              addSelectedItem={addSelectedItem}
              decreaseItemQuantity={decreaseItemQuantity}
              isItemInCart={isItemInCart}
              selectedItems={selectedItems}
            />
          ))}
        </IonCol>
      </IonRow>
      <IonRow>
        <CategorySelector
          categories={Object.keys(cateringMenu)}
          selectedCategory={selectedCategory}
          onCategoryChange={handleSegmentChange}
        />
      </IonRow>
    </div>
  );
};

const MenuItemCard = ({
  item,
  category,
  addSelectedItem,
  decreaseItemQuantity,
  isItemInCart,
  selectedItems,
}) => {
  const itemInCart = isItemInCart(item.name);
  const itemQuantity =
    selectedItems.find((cartItem) => cartItem.name === item.name)?.quantity ||
    0;

  return (
    <div className="flex-none w-[300px] min-w-[300px] flex justify-center items-center snap-center rounded-md">
      <IonCard className="min-h-40 w-full">
        {/* <img  src="https://placehold.co/600x400"></img> */}
        <IonCardHeader className="relative pt-10">
          <IonCardSubtitle>{item.category}</IonCardSubtitle>
          <IonCardTitle className="text-lg">{item.name}</IonCardTitle>
          <span className="absolute top-2 right-2 text-xl font-bold">
            ${item.price || 0}.00
          </span>
        </IonCardHeader>
        <IonCardContent className="text-xs">
          <IonText className="line-clamp-3 min-h-12">
            {item.description}
          </IonText>
        </IonCardContent>
        <IonRow className="ion-justify-content-between ion-align-items-center">
          <IonCol size="auto">
            {itemInCart && (
              <IonButton
                size="small"
                color="danger"
                onClick={() => decreaseItemQuantity(item.name)}
              >
                <IonIcon icon={removeCircle} />
              </IonButton>
            )}
            <IonButton
              size="small"
              onClick={() => addSelectedItem(item, category)}
            >
              <IonIcon icon={addCircle} />
            </IonButton>
          </IonCol>
          <IonCol size="auto">
            {itemInCart && <IonText>Quantity: {itemQuantity}</IonText>}
          </IonCol>
        </IonRow>
      </IonCard>
    </div>
  );
};

const CategorySelector = ({
  categories,
  selectedCategory,
  onCategoryChange,
}) => (
    <IonSegment
      scrollable={true}
      value={selectedCategory}
      onIonChange={onCategoryChange}
      className="flex justify-start gap-2 bg-mYellow rounded-full text-slate-800"
    >
      {categories.map((category) => (
        <IonSegmentButton
          key={category}
          value={category}
          className="flex-none w-auto text-center"
        >
          <IonLabel className="text-sm">
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </IonLabel>
        </IonSegmentButton>
      ))}
    </IonSegment>
);

export default MenuSelectionStep;
