import moment from "moment";

// Centralized array of blocked dates
const blockedDates = [
  "November 28, 2024",
  "November 29, 2024",
  "December 25, 2024",
  "December 26, 2024",
  "December 27, 2024",
  "December 28, 2024",
  "December 31, 2024",
  "January 1, 2025",
];

const dateManager = {
  // Calculate minimum date based on current day
  getMinDate: () => {
    const today = new Date();
    let cutoffDate = new Date(today);

    let day = cutoffDate.getDay();
    if (day === 0) cutoffDate.setDate(cutoffDate.getDate() + 4); // Move to Thursday
    else if (day === 6) cutoffDate.setDate(cutoffDate.getDate() + 5); // Move to Thursday
    else {
      cutoffDate.setDate(cutoffDate.getDate() + 2);
      if (cutoffDate.getDay() === 0) cutoffDate.setDate(cutoffDate.getDate() + 3);
      else if (cutoffDate.getDay() === 1) cutoffDate.setDate(cutoffDate.getDate() + 2);
    }

    return cutoffDate;
  },

  // Calculate maximum date 3 months from today
  getMaxDate: () => {
    return moment().add(3, "months").toDate();
  },

  // Generate next 7 available dates (excluding Sundays, Mondays, and blocked dates)
  getAvailableDates: (minDate) => {
    const availableDates = [];
    let currentDate = moment(minDate);

    while (availableDates.length < 7) {
      const formattedDate = currentDate.format("MMMM D, YYYY");

      if (dateManager.isDateAvailable(currentDate.toDate())) {
        availableDates.push(formattedDate);
      }
      currentDate.add(1, "day");
    }

    return availableDates;
  },

  // Check if a date is available
  isDateAvailable: (date) => {
    const formattedDate = moment(date).format("MMMM D, YYYY");
    return (
      date.getDay() !== 0 && // Exclude Sundays
      date.getDay() !== 1 && // Exclude Mondays
      !blockedDates.includes(formattedDate) // Exclude blocked dates
    );
  },
};

export default dateManager;
