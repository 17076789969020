import React, { useState } from "react";
import {
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import { useMenu } from "../../../context/MenuContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import marcellas from "../../../assets/Images/marcellas.jpg";

import {
  ReviewStep,
  MenuSelectionStep,
  PickupInfoStep,
  BasicInfoStep,
  DeliveryInfoStep,
  StartPreorderStep,
} from "../../../components/Layout/Steps";
import ConfirmationStep from "../Steps/ConfirmationStep";
import { useSite } from "../../../context/SiteContext";

const Preorder = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [date, setDate] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [errors, setErrors] = useState({});
  const { cateringMenu, loading, error } = useMenu();
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const { isOrderingActive } = useSite(); // Access global state

  const [categories, setCategories] = useState([
    {
      key: Date.now(),
      items: [{ key: Date.now(), value: "", quantity: 1 }],
      selectedCategory: "",
    },
  ]);

  const [formData, setFormData] = useState({
    orderName: "",
    phoneNumber: "",
    email: "",
    serviceType: "",
    pickupTime: "",
    tempType: "",
    comments: "",
    selectedItems: [],
    date: null, // Consolidated date field
    street: "",
    city: "",
    state: "California",
  });

  const next = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const validateStep = (step) => {
    let isValid = true;
    const newErrors = {};

    // Dismiss any previous toast notifications
    toast.dismiss();

    switch (step) {
      case 1:
        if (!formData.orderName) {
          newErrors.orderName = "Order Name is required";
          toast.error("Order Name is required");
          isValid = false;
          break; // Exit after the first error
        }
        if (!formData.phoneNumber) {
          newErrors.phoneNumber = "Phone Number is required";
          toast.error("Phone Number is required");
          isValid = false;
          break;
        }
        if (!formData.email) {
          newErrors.email = "Email is required";
          toast.error("Email is required");
          isValid = false;
          break;
        }
        if (!formData.serviceType) {
          newErrors.serviceType = "Service type is required";
          toast.error("Service type is required");
          isValid = false;
          break;
        }
        break;

      case 2:
        if (!formData.date) {
          newErrors.date = `${formData.serviceType} date is required`;
          toast.error(`${formData.serviceType} date is required`);
          isValid = false;
          break;
        }
        if (formData.serviceType === "Pickup" && !formData.pickupTime) {
          newErrors.pickupTime = "Pickup time is required";
          toast.error("Pickup time is required");
          isValid = false;
          break;
        }
        if (!formData.tempType) {
          newErrors.tempType = "Temperature preference is required";
          toast.error("Temperature preference is required");
          isValid = false;
          break;
        }
        if (formData.serviceType === "Delivery" && !formData.street) {
          newErrors.street = "Street is required for delivery";
          toast.error("Street is required for delivery");
          isValid = false;
          break;
        }
        if (formData.serviceType === "Delivery" && !formData.city) {
          newErrors.city = "City is required for delivery";
          toast.error("City is required for delivery");
          isValid = false;
          break;
        }
        break;

      case 3:
        if (selectedItems.length === 0) {
          newErrors.items = "At least one item must be selected";
          toast.error("At least one item must be selected");
          isValid = false;
          break;
        }
        break;

      default:
        break;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateStep(currentStep)) return;

    const categories = formData.selectedItems.map((item) => ({
      category: item.category || "N/A",
      items: `${item.name} (Quantity: ${item.quantity})` || "N/A",
    }));

    const templateParams = {
      orderName: formData.orderName || "",
      phoneNumber: formData.phoneNumber || "",
      email: formData.email || "",
      serviceType: formData.serviceType || "",
      pickupTime:
        formData.serviceType === "Pickup"
          ? formData.pickupTime || "N/A"
          : "N/A",
      tempType: formData.tempType || "N/A",
      date: formData.date || "N/A",
      street: formData.street || "N/A",
      city: formData.city || "N/A",
      state: formData.state || "California",
      deliveryTime:
        formData.serviceType === "Delivery"
          ? formData.deliveryTime || "N/A"
          : "N/A",
      comments: formData.comments || "No additional comments",
      categories, // Include updated categories with quantities
    };

    emailjs
      .send(
        "service_marcellas",
        "template_mi55j1p",
        // "service_testing",
        // "template_lmnmfvs",
        templateParams,
        "OXVmMrXHHOEpr832j"
        // "tx3Q3atJsYPq2xTPe"
      )
      .then((response) => {
        setSubmitted(true);
        setEmailLoading(false);
        next();
      })
      .catch((err) => {
        setEmailLoading(false);
        toast.error("Failed to submit order.");
      });
  };
  
  const resetForm = () => {
    setCurrentStep(0);
    setDate(null);
    setSelectedItems([]);
    setCategories([
      {
        key: Date.now(),
        items: [{ key: Date.now(), value: "", quantity: 1 }],
        selectedCategory: "",
      },
    ]);
    setFormData({
      orderName: "",
      phoneNumber: "",
      email: "",
      serviceType: "",
      pickupTime: "",
      tempType: "",
      comments: "",
      selectedItems: [],
      date: null,
      street: "",
      city: "",
      state: "California",
    });
    setErrors({});
  };

  const steps = [
    {
      title: "Catering/Pickup",
      subtitle: "Preorder!",
      img: marcellas,
      content: (
        <StartPreorderStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
      ),
    },
    {
      title: "Contact",
      subtitle: "Basic Details",
      content: (
        <BasicInfoStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
      ),
    },
    {
      title: formData.serviceType === "Pickup" ? "Pickup" : "Delivery/Catering",
      subtitle:
        formData.serviceType === "Pickup"
          ? "In Store"
          : "Local Gems",
      content:
        formData.serviceType === "Pickup" ? (
          <PickupInfoStep
            formData={formData}
            setFormData={setFormData}
            date={date}
            setDate={setDate}
            errors={errors}
          />
        ) : (
          <DeliveryInfoStep
            formData={formData}
            setFormData={setFormData}
            date={date}
            setDate={setDate}
            errors={errors}
          />
        ),
    },
    {
      title: "Catering Menu",
      subtitle: "Select Items",
      content: (
        <MenuSelectionStep
          cateringMenu={cateringMenu}
          categories={categories}
          setCategories={setCategories}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setFormData={setFormData}
          formData={formData}
          loading={loading}
          error={error}
          errors={errors}
        />
      ),
    },
    {
      title: "Order Summary",
      subtitle: "Review your order",
      content: (
        <ReviewStep
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems} // Ensure this is passed correctly
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      title: "Confirmation",
      subtitle: "",
      img:"http://placeholder.co/200x200",
      content: (
        <ConfirmationStep
          emailLoading={emailLoading}
          submitted={submitted}
          onRetry={handleSubmit}
          onHome={() => history.push("/")}
          resetForm={resetForm}
        />
      ),
    },
  ];

  return (
    <IonContent color="light" scroll-y="false">
      <IonGrid className="p-0 m-0 h-full w-full max-w-xl mx-auto relative flex flex-col">
        <IonCard className="w-full p-0 md:mt-4 m-0 mx-auto md:rounded-xl flex flex-col flex-grow">
          <ToastContainer className="absolute top-30 left-1/2 z-20"
            autoClose={500}
            position="top-center"
            hideProgressBar={true}
            closeOnClick
            pauseOnHover
            draggable
            limit={1}
          />
          {steps[currentStep].img && <img className="object-cover h-40 w-full" src={steps[currentStep].img}/>}            
          <IonCardHeader>
            <IonCardSubtitle>
              {steps[currentStep].subtitle}
            </IonCardSubtitle>
            <IonCardTitle>
              {steps[currentStep].title}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="relative flex-grow container overflow-y-auto">
            {steps[currentStep].content}
          </IonCardContent>
        </IonCard>

        <IonRow className="ion-align-items-center ion-justify-content-center flex items-center justify-center">
            {/* Back Button */}
            {currentStep > 0 && currentStep < steps.length - 1 && (
              <IonCol size="auto">
                <IonButton
                  color="danger"
                  onClick={prev}
                  shape="round"
                  size="small"
                >
                  Back
                </IonButton>
              </IonCol>
            )}

            {/* Start Order Button (Visible only on the first step) */}
            {currentStep === 0 && (
              <IonCol size="auto">
                <IonButton
                  color="primary"
                  shape="round"
                  onClick={isOrderingActive ? next : null}
                  disabled={!isOrderingActive} // Disable if `isOrderingActive` is false
                  size="small"
                >
                  {isOrderingActive ? "Start Order" : "Ordering Closed"}
                </IonButton>
              </IonCol>
            )}

            {/* Next Button (Visible after the first step, but before the last step) */}
            {currentStep > 0 && currentStep < steps.length - 2 && (
              <IonCol size="auto">
                <IonButton
                  color="primary"
                  shape="round"
                  onClick={next}
                  size="small"
                >
                  Next
                </IonButton>
              </IonCol>
            )}

            {/* Order Button (Visible on the second last step) */}
            {currentStep === steps.length - 2 && (
              <IonCol size="auto">
                <IonButton
                  color="primary"
                  shape="round"
                  onClick={handleSubmit}
                  size="small"
                >
                  Order
                </IonButton>
              </IonCol>
            )}

            {/* Reset Button (Visible on the last step) */}
            {currentStep === steps.length - 1 && (
              <IonCol size="auto">
                <IonButton onClick={resetForm} color="primary">
                  Start New Order
                </IonButton>
              </IonCol>
            )}
        </IonRow>
      </IonGrid> 
    </IonContent>
  );
};

export default Preorder;
