import {
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
  IonList,
  IonListHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonIcon,
  IonBadge,
  IonModal,
  IonCardContent,
  IonText,
} from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import React, { useState } from "react";

const StartPreorderStep = () => {
  return (
    <IonRow className="ion-justify-content-between h-full">
      <IonCol size="12" className="text-center">
        <IonText className="">
          🎉 Buon Anno! Start the New Year right with some delicious eats from
          Marcella’s Lasagneria!
        </IonText>
        <IonText className="pt-8">
          Just a friendly heads-up—our lasagna doesn’t bake itself! 🍝 Preorders
          need at least 48 hours’ notice, so we can serve you the freshest, most
          mouthwatering dishes. Orders with shorter notice? We’ll do our best,
          but it depends on what’s cookin’ in the kitchen. As soon as you place
          your order, our team will get to work, and you’ll get a confirmation
          email faster than you can say “Mangia!” 🥂 Cheers to good food and
          great vibes in the New Year! 🥖🇮🇹
        </IonText>
      </IonCol>

      <IonCol size="12" className="ion-align-self-end">
        <div className="rounded-lg bg-red-500 flex items-center justify-center ion-no-margin ion-no-padding">
          <IonIcon
            className="p-2"
            color="light"
            size="large"
            icon={informationCircle}
          ></IonIcon>
          <IonText color="light" className="text-xs pl-1">
            Updates are still being made to improve this ordering system.
          </IonText>
        </div>
      </IonCol>
    </IonRow>
  );
};

export default StartPreorderStep;
