import React from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonRouterLink,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { motion } from "framer-motion";
import localGemsLogo from "../assets/Logos/localgems-logo.png";
import photo3 from "../assets/Images/LocalGems/catering-1.png";
import photo1 from "../assets/Images/LocalGems/catering-3.png";
import { closeCircle } from "ionicons/icons";

function Delivery({ setActiveTab, setShowCateringModal }) { // Updated prop name
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Delivery w/ Local Gems</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="p-0 bg-white">
        <IonGrid className="h-full flex flex-col justify-between">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <IonRow>
              <IonCol size="6" className="mx-auto rounded-xl">
                <div className="rounded-md">
                  <IonImg
                    className="object-fit h-full aspect-video"
                    src={localGemsLogo}
                    alt="local-gems-logo"
                  />
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCard>
                <IonImg
                  className="object-cover h-60 w-full"
                  src={photo3}
                  alt="Catering with Local Gems"
                />
                <IonCardHeader>
                  <IonCardTitle>Local Gems</IonCardTitle>
                  <IonCardSubtitle>Catering with</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  We’ve partnered with Local Gems, a trusted local business, to
                  handle all catering deliveries for offices and private events.
                  Whether you're planning a business meeting, a celebration, or
                  any special event, our catering services offer a seamless and
                  delicious experience. Local Gems will ensure your catering
                  order arrives on time, fresh, and ready to serve.
                </IonCardContent>
                <IonRow>
                  <IonCol size="auto" className="pl-4">
                    <IonRouterLink
                      href="https://www.shoplocalgems.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IonButton color="tertiary">Website</IonButton>
                    </IonRouterLink>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonRow>

            <IonRow>
              <IonCard>
                <IonImg
                  className="object-cover h-40 w-full"
                  src={photo1}
                  alt="Order Catering"
                />
                <IonCardHeader>
                  <IonCardTitle>Order Now</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  You can easily place your catering order through our
                  Preorder system. Simply select your menu items, choose your
                  delivery details, and let Local Gems take care of the rest!
                </IonCardContent>
                <IonRow>
                  <IonCol size="auto" className="pl-4">
                    <IonButton onClick={() => setActiveTab("preorder")}>
                      Preorder
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonRow>

            <IonRow>
              <IonCol size="12" className="text-center"></IonCol>
            </IonRow>
          </motion.div>
        </IonGrid>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonRow>
            <IonCol size="auto" className="mx-auto p-0">
              <IonButton
                color="danger"
                size="small"
                onClick={() => setShowCateringModal(false)} // Correct function reference
              >
                <IonIcon icon={closeCircle}></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </>
  );
}

export default Delivery;
