import React, { useEffect, useState } from "react";
import {
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  IonText,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
} from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import dateManager from "./dateManager"; // Import the centralized dateManager utility

const PickupInfoStep = ({ formData, setFormData, date, setDate }) => {
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);

  // Fetch minDate and maxDate on component mount
  useEffect(() => {
    setMinDate(dateManager.getMinDate());
    setMaxDate(dateManager.getMaxDate());
  }, []);

  // Update available dates based on minDate
  useEffect(() => {
    if (minDate) {
      setAvailableDates(dateManager.getAvailableDates(minDate));
    }
  }, [minDate]);

  // Handle calendar date selection
  const handleDateChange = (selectedDate) => {
    if (!dateManager.isDateAvailable(selectedDate)) {
      alert("Selected date is not available.");
      return;
    }

    const formattedDate = moment(selectedDate).format("MMMM D, YYYY");
    setDate(selectedDate);
    setFormData((prevData) => ({ ...prevData, date: formattedDate }));
    setShowCalendar(false);
  };

  // Handle dropdown date selection
  const handleDropdownChange = (selectedDateString) => {
    const dateObj = moment(selectedDateString, "MMMM D, YYYY").toDate();
    setDate(dateObj);
    setFormData((prevData) => ({ ...prevData, date: selectedDateString }));
  };

  return (
    <IonRow className="h-full w-full overflow-auto">
      <IonCol size="12">
          <IonList className="w-full">
            <IonRow>
              <IonCol size="12" className="text-left text-gray-600">
                <IonText>
                  Pickups are in store. Please complete the pickup details for
                  your order. Our pickups require at least 48 hours in advance.
                </IonText>
              </IonCol>
              <IonCol size="12" className="text-center text-xs text-mRed">
              <span>
                *Use the calendar to select future dates not displayed.
              </span>
              </IonCol>
            </IonRow>

            {/* Calendar Section */}
            {showCalendar && (
              <IonRow>


              <IonCol size="12" sizeMd="6" className="px-0">
                <Calendar
                  onChange={handleDateChange}
                  value={date}
                  minDate={minDate}
                  maxDate={maxDate}
                  className="text-mGreen mx-auto border-4 drop-shadow-md border-mGreen p-2"
                  tileDisabled={({ date }) => !dateManager.isDateAvailable(date)}
                />
              </IonCol>
              </IonRow>
            )}

            {/* Dropdown and Calendar Toggle */}
            <IonRow className="mx-auto flex items-end justify-center">
              <IonCol size="9" sizeMd="10">
                <IonSelect
                  label="Pickup Date"
                  labelPlacement="floating"
                  className="rounded-md bg-slate-100 text-slate-800 px-2 w-full"
                  onIonChange={(e) => handleDropdownChange(e.detail.value)}
                  value={date ? moment(date).format("MMMM D, YYYY") : undefined}
                >
                  {availableDates.map((day, index) => (
                    <IonSelectOption key={index} value={day}>
                      {day}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>

              <IonCol size="3" sizeMd="2" className="text-center">
                <span className="text-xs">Calendar</span>
                <IonButton
                  className="w-full"
                  shape="square"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <IonIcon slot="icon-only" icon={calendarOutline}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>

            {/* Additional Pickup Info */}
            <IonRow className="px-0">
              <IonCol size="12" sizeMd="6">
                  <IonSelect
                    labelPlacement="floating"
                    value={formData.pickupTime}
                    label="Pickup Time"
                    className="bg-slate-50 text-slate-800 px-2 rounded-md"
                    onIonChange={(e) =>
                      setFormData({ ...formData, pickupTime: e.detail.value })
                    }
                  >
                    <IonSelectOption value="10am">10am</IonSelectOption>
                    <IonSelectOption value="11am">11am</IonSelectOption>
                    <IonSelectOption value="2pm">2pm</IonSelectOption>
                  </IonSelect>
              </IonCol>

              <IonCol size="12" sizeMd="6">
                  <IonSelect
                    value={formData.tempType}
                    labelPlacement="floating"
                    label="Food Temp"
                    className="bg-slate-50 text-slate-800 px-2 rounded-md"
                    onIonChange={(e) =>
                      setFormData({ ...formData, tempType: e.detail.value })
                    }
                  >
                    <IonSelectOption value="Cold">Cold</IonSelectOption>
                    <IonSelectOption value="Hot">Hot</IonSelectOption>
                  </IonSelect>
              </IonCol>
            </IonRow>
          </IonList>
      </IonCol>
    </IonRow>
  );
};

export default PickupInfoStep;