import React, { useEffect, useState } from "react";
import {
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  IonText,
  IonInput,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
} from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import dateManager from "./dateManager"; // Import the centralized dateManager utility

const DeliveryInfoStep = ({ formData, setFormData, date, setDate }) => {
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);

  // Fetch minDate and maxDate on component mount
  useEffect(() => {
    setMinDate(dateManager.getMinDate());
    setMaxDate(dateManager.getMaxDate());
  }, []);

  // Update available dates based on minDate
  useEffect(() => {
    if (minDate) {
      setAvailableDates(dateManager.getAvailableDates(minDate));
    }
  }, [minDate]);

  // Handle calendar date selection
  const handleDateChange = (selectedDate) => {
    if (!dateManager.isDateAvailable(selectedDate)) {
      alert("Selected date is not available.");
      return;
    }

    const formattedDate = moment(selectedDate).format("MMMM D, YYYY");
    setDate(selectedDate);
    setFormData((prevData) => ({ ...prevData, date: formattedDate }));
    setShowCalendar(false);
  };

  // Handle dropdown date selection
  const handleDropdownChange = (selectedDateString) => {
    const dateObj = moment(selectedDateString, "MMMM D, YYYY").toDate();
    setDate(dateObj);
    setFormData((prevData) => ({ ...prevData, date: selectedDateString }));
  };

  return (
    <IonRow className="overflow-auto w-full h-full">
      <IonCol size="12">
          <IonList className="w-full">
            <IonRow>
              <IonCol size="12" className="text-left text-gray-600">
                <IonText className="text-xs">
                  Catering is done through a 3rd Party small business, please
                  contact them with any delivery requests. Local Gems at (832)
                  693-9729, or email them at contact@shoplocalgems.com.
                </IonText>
              </IonCol>
            </IonRow>

            {/* Calendar Section */}
            {showCalendar && (
              <IonRow>
                <IonCol size="12" sizeMd="8" className="mx-auto">
                  <Calendar
                    onChange={handleDateChange}
                    value={date}
                    minDate={minDate}
                    maxDate={maxDate}
                    className="text-mGreen mx-auto border-4 drop-shadow-md border-mGreen p-2"
                    tileDisabled={({ date }) => !dateManager.isDateAvailable(date)}
                  />
                </IonCol>
              </IonRow>
            )}

            {/* Dropdown and Calendar Toggle */}
            <IonRow className="mx-auto flex items-end justify-center ">
              <IonCol size="9" sizeMd="10" className="p-0">
                <IonSelect
                  label="Delivery Date"
                  labelPlacement="floating"
                  className="rounded-md bg-slate-100 text-slate-800 px-2 w-full"
                  onIonChange={(e) => handleDropdownChange(e.detail.value)}
                  value={date ? moment(date).format("MMMM D, YYYY") : undefined}
                >
                  {availableDates.map((day, index) => (
                    <IonSelectOption key={index} value={day}>
                      {day}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
              <IonCol size="3" sizeMd="2" className="text-center">
                <span className="text-xs text-mRed">Calendar *</span>
                <IonButton
                  className="w-full"
                  shape="square"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <IonIcon slot="icon-only" icon={calendarOutline}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>

            {/* Food Temp Selection */}
            <IonRow className="">
              <IonCol size="12" className="px-0">
                  <IonSelect
                    value={formData.tempType}
                    labelPlacement="floating"
                    label="Food Temp"
                    className="bg-slate-100 text-slate-800 px-2 rounded-md"
                    onIonChange={(e) =>
                      setFormData({ ...formData, tempType: e.detail.value })
                    }
                  >
                    <IonSelectOption value="Cold">Cold</IonSelectOption>
                    <IonSelectOption value="Hot">Hot</IonSelectOption>
                  </IonSelect>
              </IonCol>
            </IonRow>

            {/* Address Inputs */}
            <IonRow className="ion-justify-content-center gap-2">
            <IonCol size="12" sizeMd="5" className="bg-slate-100 text-slate-800 px-2 rounded-md">
                  <IonInput
                    placeholder="ex.1099 Tennessee St."
                    label="Street"
                    value={formData.street}
                    onIonChange={(e) =>
                      setFormData({ ...formData, street: e.detail.value })
                    }
                  />
              </IonCol>
              <IonCol size="12" sizeMd="5" className="bg-slate-100 text-slate-800 px-2 rounded-md">
                  <IonSelect
                    value={formData.city}
                    labelPlacement="floating"
                    label="City"
                    onIonChange={(e) =>
                      setFormData({ ...formData, city: e.detail.value })
                    }
                  >
                    <IonSelectOption value="San Francisco">San Francisco</IonSelectOption>
                  </IonSelect>
              </IonCol>
              <IonCol size="12" className="text-center text-mRed">
              <IonText className="text-xs">
                *Use the calendar to select future dates not displayed.
              </IonText>
              </IonCol> 
            </IonRow>
          </IonList>
      </IonCol>
    </IonRow>
  );
};

export default DeliveryInfoStep;
